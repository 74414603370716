<script lang="ts">
  import { onMount } from "svelte";
  import { db } from "./firebase";

  export let user;
  let query = db.collection("subscribers").where("active", "==", true);
  let students = [];
  let filteredStudents = [];

  let schedules = [];
  let schedule;

  let schools = [];
  let grades = [];

  let grade;
  let school;
  let operator;

  let total;
  let noResponse;
  let completed;

  const getNumbers = () => {
    completed = 0;
    noResponse = 0;
    let studentsAfterFilter;
    operator
      ? (studentsAfterFilter = filteredStudents.filter(
          student => operator === student.telecomProvider
        ))
      : (studentsAfterFilter = filteredStudents);
    total = studentsAfterFilter.length;
    studentsAfterFilter.forEach(student => {
      if (
        student.completedModules.includes(
          schedule[student.organisation][student.grade]
        )
      ) {
        completed += 1;
      } else if (
        !student[schedule.field] ||
        !student[schedule.field][schedule[student.organisation][student.grade]]
      ) {
        noResponse += 1;
      }
      // else {
      //   console.log(student);
      // }
    });
  };

  onMount(async () => {
    await db
      .collection("schedule")
      .orderBy("timestamp", "desc")
      .get()
      .then(qs => {
        schedule = qs.docs[0].data();
        schedules = qs.docs.map(doc => doc.data());
      });
    switch (user.role) {
      case "TEACHER":
        query = query
          .where("teacherPhone", "==", user.phoneNumber.slice(4))
          .orderBy("name");
        break;
      case "DIRECTOR":
        query = query.where("school", "==", user.school).orderBy("name");
        break;
      case "ORGANISER":
        query = query
          .where("organisation", "==", user.organisation)
          .orderBy("name");
        break;
      case "ADMIN":
        query = query.orderBy("name");
    }
    query.get().then(qs => {
      students = qs.docs
        .filter(item => item.data().group !== "SHH-T")
        .map(doc => {
          const id = doc.id;
          if (!grades.includes(doc.data().grade))
            grades = [...grades, doc.data().grade];
          if (!schools.includes(doc.data().school))
            schools = [...schools, doc.data().school];
          return { ...doc.data(), id };
        });
      filteredStudents = students;
      getNumbers();
    });
  });

  const filter = () => {
    if (grade && school) {
      filteredStudents = students.filter(student => {
        return student.grade === grade && student.school === school;
      });
      getNumbers();
      return;
    }
    if (grade) {
      filteredStudents = students.filter(student => student.grade === grade);
      getNumbers();
      return;
    }
    if (school) {
      filteredStudents = students.filter(student => student.school === school);
      getNumbers();
      return;
    }
    filteredStudents = students;
    getNumbers();
  };
</script>

<h2>Reporte Diario</h2>

{#if students.length && schedule}
  {#if schedules.length}
    <label>
      Fecha:
      <select bind:value={schedule} on:change={getNumbers} on:blur={getNumbers}>
        {#each schedules as scheduleI}
          <option value={scheduleI}>
            {scheduleI.timestamp.toDate().toLocaleDateString()}
          </option>
        {/each}
      </select>
    </label><br /><br />
  {/if}
  {#if (user.role === 'ORGANISER' || user.role === 'ADMIN') && schools.length > 1}
    <label>
      Escuela:
      <select bind:value={school} on:blur={filter} on:change={filter}>
        <option />
        {#each schools as school}
          <option value={school}>{school}</option>
        {/each}
      </select>
    </label><br /><br />
  {/if}
  {#if grades.length > 1}
    <label>
      Grado:
      <select bind:value={grade} on:blur={filter} on:change={filter}>
        <option />
        {#each grades as grade}
          <option value={grade}>{grade}</option>
        {/each}
      </select>
    </label><br /><br />
  {/if}
  <label>
    Operador:
    <select bind:value={operator} on:change={getNumbers} on:blur={getNumbers}>
      <option />
      <option value="T">Tigo</option>
      <option value="C">Claro</option>
    </select>
  </label><br /><br />
  {#if filteredStudents.length}
    <div class="numbersDiv">
      <div>Total: {total}</div>
      <div>Sin respuesta: {noResponse}</div>
      <div>Realizado: {completed}</div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Número de teléfono</th>
          <th>Operador</th>
          <th>Nombre</th>
          <th>Escuela</th>
          <th>Grado</th>
          <th>{schedule['field'] === 'responses' ? 'Respuesta' : 'Cuenta'}</th>
        </tr>
      </thead>
      <tbody>
        {#each operator ? filteredStudents.filter(student => student.telecomProvider === operator) : filteredStudents as student (student.id)}
          <tr
            class:green={student.completedModules.includes(schedule[student.organisation][student.grade])}>
            <td>{student.phone}</td>
            <td>{student.telecomProvider}</td>
            <td>{student.name}</td>
            <td>{student.school}</td>
            <td>{student.grade}</td>
            {#if student[schedule.field] && student[schedule.field][schedule[student.organisation][student.grade]]}
              <td>
                {schedule.field === 'responses' ? student[schedule.field][schedule[student.organisation][student.grade]] : `${student[schedule.field][schedule[student.organisation][student.grade]].correct}/${student[schedule.field][schedule[student.organisation][student.grade]].total}`}
              </td>
            {:else}
              <td>No respondió</td>
            {/if}
          </tr>
        {/each}
      </tbody>
    </table><br />
  {:else}
    <p>No hay informes para mostrar</p>
  {/if}
{:else}
  <p>No hay informes para mostrar</p>
{/if}

<style>
  .green {
    background-color: lightgreen;
  }
  .numbersDiv {
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    td:nth-of-type(1):before {
      content: "Teléfono";
    }
    td:nth-of-type(2):before {
      content: "Nombre";
    }
    td:nth-of-type(3):before {
      content: "Operador";
    }
    td:nth-of-type(4):before {
      content: "Escuela";
    }
    td:nth-of-type(5):before {
      content: "Grado";
    }
    td:nth-of-type(6):before {
      content: "Respuesta";
    }
  }
</style>
