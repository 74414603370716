<script lang="ts">
  import { db, timestamp } from "./firebase";

  export let user;

  let selection = "change";
  let phone;
  let oldPhone;
  let newPhone;
  let telecomProvider;
  let msg;

  const groupSelector = {
    "World Vision": {
      3: "WV-3",
      4: "WV-4",
      5: "WV-5",
      6: "WV-6",
    },
    CRS: {
      3: "CRS-3",
      4: "CRS-4",
      5: "CRS-5",
      6: "CRS-6",
    },
    SHH: {
      3: "SHH-3",
      4: "SHH-4",
      5: "SHH-5",
      6: "SHH-6",
    },
  };

  const submit = () => {
    msg = "";
    if (selection === "change") {
      if (!(oldPhone.trim().length + newPhone.trim().length === 16)) {
        msg = "Ingrese un número de teléfono válido (sin +504)";
        return;
      }
      db.collection("requests")
        .add({
          oldPhone: oldPhone.trim(),
          newPhone: newPhone.trim(),
          timestamp: timestamp.now(),
          telecomProvider,
          type: selection,
          requestedBy: user.phone,
          done: false,
        })
        .then(() => (msg = "Éxito"));
    } else if (selection === "remove") {
      if (!(phone.trim().length === 8)) {
        msg = "Ingrese un número de teléfono válido (sin +504)";
        return;
      }
      db.collection("requests")
        .add({
          phone: phone.trim(),
          timestamp: timestamp.now(),
          type: selection,
          requestedBy: user.phone,
          done: false,
        })
        .then(() => (msg = "Éxito"));
    }
    phone = oldPhone = newPhone = "";
  };
</script>

<label>
  Tipo de solicitud:
  <select
    bind:value={selection}
    on:change={() => (phone = oldPhone = newPhone = '')}
    on:blur={() => (phone = oldPhone = newPhone = '')}>
    <!-- <option value="add">Add student</option> -->
    <option value="remove">Eliminar estudiante</option>
    <option value="change">Cambie el número</option>
  </select>
</label><br /><br />

{#if selection === 'remove'}
  <label>Número: <input bind:value={phone} /> </label>
{:else}
  <label> Número antiguo: <input bind:value={oldPhone} /> </label><br />
  <label> Nuevo número: <input bind:value={newPhone} /> </label><br />
  <label>
    Proveedor de telecomunicaciones:
    <select bind:value={telecomProvider}>
      <option value="" />
      <option value="T">Tigo</option>
      <option value="C">Claro</option>
    </select></label><br />
{/if}
<br />
<button
  on:click={submit}
  disabled={!phone && (!telecomProvider || !oldPhone || !newPhone)}>Enviar</button>
<br />
{msg ?? ''}
