<script lang="ts">
  import { onMount } from "svelte";
  import { db } from "./firebase";

  export let user;
  // let user = {
  //   role: "ORGANISER",
  //   organisation: "SHH",
  //   school: "",
  // };
  let field;
  let defaultField;
  let data;
  let fieldOptions = [];
  const schools = {
    "World Vision": [
      "15 DE MARZO",
      "Angel Zuniga Huete",
      "CEB Dos de Marzo",
      "Esteban Guardiola",
      "Jesús Milla Selva",
      "Jose Cecilio del Valle",
      "Jose Osman Ramos",
      "Manuel de Jesus Morales",
      "Marco Aurelio Soto",
      "Tres de Abril",
    ],
    CRS: [
      "APLICACIÓN HONDURAS",
      "CEB José Trinidad Reyes",
      "CEB Lempira",
      "CEB Marco Aurelio Soto",
      "CENTRO EXPERIMENTAL NORMAL DE OCCIDENTE",
      "ESCUELA JOSE TRINIDAD REYES",
      "FRATERNIDAD",
      "Francisco Morazan MGD1",
      "Francisco Morazan MGD2",
      "Francisco Morazan MGD3",
      "Francisco Morazan SEL1",
      "JUAN LINDO",
      "JULIANA VASQUEZ",
      "LA UNION",
    ],
    SHH: [
      "EDITH MARISELA FIGUEROA",
      "Heleodora de Panchame",
      "Marina Yolanda Melendez",
      "PROF. FLORENCIO REYES SABILLON",
    ],
  };

  const plot = () => {
    const x = data.map(item =>
      item.timestamp.toDate().toLocaleDateString().slice(0, 5)
    );
    const completedPercentage = data.map(item => {
      const fieldValue = field ? item[field] : item[defaultField];
      if (!fieldValue.completed) return 0;
      const percentage =
        Math.round((fieldValue.completed / fieldValue.total) * 100 * 100) /
          100 ?? 0;
      return percentage;
    });
    const attemptedPercentage = data.map(item => {
      const fieldValue = field ? item[field] : item[defaultField];
      if (!fieldValue.attempted) return 0;
      const completed = fieldValue.completed ? fieldValue.completed : 0;
      const percentage =
        Math.round(
          ((fieldValue.attempted - completed) / fieldValue.total) * 100 * 100
        ) / 100;
      return percentage;
    });
    const trace1 = {
      x,
      y: attemptedPercentage,
      name: "% De intentos sin completar",
      type: "bar",
      marker: {
        color: "rgba(255, 145, 72, 0.7)",
        line: {
          color: "rgba(255, 145, 72, 1)",
        },
      },
    };
    const trace2 = {
      x,
      y: completedPercentage,
      name: "% Completado",
      type: "bar",
      marker: {
        color: "rgba(235, 57, 119, 0.7)",
        line: {
          color: "rgba(235, 57, 119, 1)",
        },
      },
    };
    const datasets = [trace1, trace2];
    const layout = { barmode: "stack" };
    (Plotly as any).newPlot("graph", datasets, layout, {
      displayModeBar: false,
      responsive: true,
    });
  };

  onMount(async () => {
    if (user.role === "ADMIN") {
      defaultField = "all";
      fieldOptions = schools["World Vision"].concat(
        schools["CRS"],
        schools["SHH"]
      );
    } else if (user.role === "ORGANISER") {
      defaultField = user.organisation;
      fieldOptions = schools[user.organisation];
    } else if (user.role === "DIRECTOR") {
      defaultField = user.school;
    }

    data = await db
      .collection("reports")
      .where("detailed", "==", false)
      .orderBy("timestamp")
      .get()
      .then(qs => (data = qs.docs.map(doc => doc.data())));

    plot();
  });
</script>

<h2>Gráficos de Respuestas</h2>
{#if (user.role === 'ORGANISER' || user.role === 'ADMIN') && fieldOptions.length > 1}
  <label>
    Escuela:
    <select bind:value={field} on:blur={plot} on:change={plot}>
      <option value="">Todas las escuelas</option>
      {#each fieldOptions as fieldOption}
        <option value={fieldOption}>{fieldOption}</option>
      {/each}
    </select>
  </label>
{/if}

<div id="graph" />
<ul>
  {#if ['APLICACIÓN HONDURAS', 'JULIANA VASQUEZ'].includes(field)}
    <li>No se enviaron módulos el 23/10 según lo programado</li>
  {/if}
</ul>
