<script lang="ts">
  import { onMount } from "svelte";
  import { db, timestamp } from "./firebase";

  let crs3;
  let crs4;
  let crs5;
  let crs6;
  let modules = [];
  let msg = "";
  let disabled = false;

  onMount(() => {
    db.collection("moduleStats")
      .orderBy("module")
      .get()
      .then(qs => {
        modules = qs.docs.map(doc => doc.data());
      });
  });

  const submit = () => {
    disabled = true;
    db.collection("schedule")
      .add({
        timestamp: timestamp.now(),
        field: "tally",
        "World Vision": {
          3: "",
          4: "",
          5: "",
          6: "",
        },
        SHH: {
          3: "",
          4: "",
          5: "",
          6: "",
        },
        CRS: {
          3: crs3,
          4: crs4,
          5: crs5,
          6: crs6,
        },
      })
      .then(() => {
        msg = "Done";
        setTimeout(() => {
            msg = "";
            disabled = false
        }, 3000);
      });
  };
</script>

<h2>Cal</h2>

<label>
  CRS-3:
  <select bind:value={crs3}>
    <option value="">Select an option</option>
    {#each modules as moduleOption}
      <option value={moduleOption.module.replace("/", "_")}>{moduleOption.module}</option>
    {/each}
  </select>
</label>
<label>
  CRS-4:
  <select bind:value={crs4}>
    <option value="">Select an option</option>
    {#each modules as moduleOption}
      <option value={moduleOption.module.replace("/", "_")}>{moduleOption.module}</option>
    {/each}
  </select>
</label>
<label>
  CRS-5:
  <select bind:value={crs5}>
    <option value="">Select an option</option>
    {#each modules as moduleOption}
      <option value={moduleOption.module.replace("/", "_")}>{moduleOption.module}</option>
    {/each}
  </select>
</label>
<label>
  CRS-6:
  <select bind:value={crs6}>
    <option value="">Select an option</option>
    {#each modules as moduleOption}
      <option value={moduleOption.module.replace("/", "_")}>{moduleOption.module}</option>
    {/each}
  </select>
</label>
<br />
<br />
<button {disabled} on:click={submit}>Submit</button>
<p>{msg}</p>
