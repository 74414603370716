<script lang="ts">
  import { onMount } from "svelte";
  import { db } from "./firebase";

  let students = [];

  onMount(async () => {
    const qs = await db
      .collection("subscribers")
      .orderBy("responseCount")
      .get();
    console.log(qs.docs);
    students = qs.docs.map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { ...data, id };
    });
    console.log(students);
  });
</script>

{#if students.length}
  <table>
    <thead>
      <tr>
        <th>Número de teléfono</th>
        <th>Operador</th>
        <th>Nombre</th>
        <th>Escuela</th>
        <th>Grado</th>
        <th>Recuento de respuestas</th>
      </tr>
    </thead>
    <tbody>
      {#each students as student (student.id)}
        <tr>
          <td>{student.phone}</td>
          <td>{student.telecomProvider}</td>
          <td>{student.name}</td>
          <td>{student.school}</td>
          <td>{student.grade}</td>
          <td>{student.responseCount}</td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<style>
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    td:nth-of-type(1):before {
      content: "Teléfono";
    }
    td:nth-of-type(2):before {
      content: "Nombre";
    }
    td:nth-of-type(3):before {
      content: "Operador";
    }
    td:nth-of-type(4):before {
      content: "Escuela";
    }
    td:nth-of-type(5):before {
      content: "Grado";
    }
    td:nth-of-type(6):before {
      content: "Recuento de respuestas";
    }
  }
</style>
