<script>
  import { onDestroy, onMount } from "svelte";
  import { PROVIDER_ID, auth } from "./firebase";

  const uiConfig = {
    signInOptions: [
      {
        provider: PROVIDER_ID,
        recaptchaParameters: {
          type: "image",
          size: "invisible",
          badge: "bottomRight",
        },
        defaultCountry: "HN",
      },
    ],
  };

  const ui = new firebaseui.auth.AuthUI(auth);
  onMount(() => ui.start("#firebaseui-auth-container", uiConfig));
  onDestroy(() => ui.delete());
</script>

<div id="firebaseui-auth-container" />
