<script>
  import { onMount } from "svelte";

  import { db } from "./firebase";
  export let user;
  let logs = [];
  onMount(async () => {
    db.collection("logs")
      .where("type", "in", [
        "VALID RESPONSE",
        "INVALID RESPONSE",
        "OTHER RESPONSE",
        "OTHER RESPONSE OUTSIDE LESSON",
      ])
      // .where("timestamp", ">=", new Date(new Date().toDateString()))
      .orderBy("timestamp", "desc")
      .get()
      .then(async qs => {
        for (const docData of qs.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          return { ...data, id };
        })) {
          const subData = await db
            .collection("subscribers")
            .where("phone", "==", docData.phone)
            .get()
            .then(qsSub => qsSub.docs[0].data());
          switch (user.role) {
            case "TEACHER":
              if (subData.teacherPhone === user.phoneNumber.slice(4))
                logs = [...logs, docData];
              break;
            case "DIRECTOR":
              if (subData.school === user.school) logs = [...logs, docData];
              break;
            case "ORGANISER":
              if (subData.organisation === user.organisation)
                logs = [...logs, docData];
              break;
            case "ADMIN":
              logs = [...logs, docData];
          }
        }
      });
  });
</script>

<h2>Registros</h2>
{#if logs.length}
  <table>
    <thead>
      <tr>
        <th>Número de teléfono</th>
        <th>Nombre</th>
        <th>Respuesta</th>
        <th>Tiempo recibido</th>
      </tr>
    </thead>
    <tbody>
      {#each logs as log (log.id)}
        <tr
          class={log.type === 'VALID RESPONSE' ? 'valid' : log.type === 'INVALID RESPONSE' ? 'invalid' : ''}>
          <td>{log.phone}</td>
          <td>{log.name}</td>
          <td>{log.text}</td>
          <td>{log.timestamp.toDate().toLocaleTimeString()}</td>
        </tr>
      {/each}
    </tbody>
  </table><br />
{:else}
  <p>Ningún registro para mostrar</p>
{/if}

<style>
  .valid {
    background-color: lightgreen;
  }
  .invalid {
    background-color: coral;
  }
  /*
	Label the data
  */
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    td:nth-of-type(1):before {
      content: "Teléfono";
    }
    td:nth-of-type(2):before {
      content: "Nombre";
    }
    td:nth-of-type(3):before {
      content: "Respuesta";
    }
    td:nth-of-type(4):before {
      content: "Tiempo recibido";
    }
  }
</style>
